import { environment } from "src/environments/environment";

export const availableDSPLogos = [
	"AxleHire",
	"DHL",
	"DoorDash",
	"Favor",
	"Fedex",
	"FedEx EP",
	"FlyBuy",
	"Instacart",
	"JoeyCo",
	"Lyft",
	"Ninja",
	"Onfleet",
	"Roadie",
	"Self Delivery",
	"Shipt",
	"Skipcart",
	"Uber",
	"UPS Shipping",
	"UPS Shipping GFP",
	"UPS Shipping GF LTL",
	"UPS",
	"USPS",
	"Bungii",
	"Instacart Connect",
	"MileZero",
	"Swyft",
	"Tortoise",
	"Canada Post",
	"Dolly",
	"Grocery Runners",
	"Same Day Delivery",
	"TForce",
	"TForce Logistics",
	"MNX",
	"GoShare",
	"Walmart GoLocal",
	"Eva",
	"Island Wide",
	"Capstone",
	"Skip",
	"Canpar",
	"DHL eCommerce",
	"OnTrac V3",
];

export const pathToAssets = `${environment.awsConfig.fileStorage.bucket}/dsp-logo/4x4-round/`;

export function getDSPLogoURL(dspName?: string) {
	if (!dspName) {
		return;
	}

	if (dspName.toLowerCase().includes("fedex")) {
		let tempDsp = "fedex";
		return `${pathToAssets}${tempDsp}.svg`;
	}

	if (dspName.toLowerCase().includes("lso")) {
		let tempDsp = "Lso";
		return `${pathToAssets}${tempDsp}.svg`;
	}

	if (dspName.toLowerCase().includes("instacart")) {
		let tempDsp = "Instacart";
		return `${pathToAssets}${tempDsp}.svg`;
	}

	let isLogoavailable = availableDSPLogos.includes(dspName);
	if (isLogoavailable) {
		return `${pathToAssets}${dspName}.svg`;
	}
	if (dspName.includes("DHL eCommerce")) {
		let tempDsp = "DHL eCommerce";
		return `${pathToAssets}${tempDsp}.svg`;
	}
	if (dspName.includes("DHL")) {
		let tempDsp = "DHL";
		return `${pathToAssets}${tempDsp}.svg`;
	}
	if (dspName.includes("Shipt")) {
		let tempDsp = "Shipt";
		return `${pathToAssets}${tempDsp}.svg`;
	}
	if (dspName.includes("OnTrac V3")) {
		let tempDsp = "OnTrac V3";
		return `${pathToAssets}${tempDsp}.svg`;
	}
	if (dspName.includes("Passport")) {
		const tempDsp = "Passport";
		return `${pathToAssets}${tempDsp}.svg`;
	}

	return `${pathToAssets}Self Delivery.svg`;
}
